import {ChakraProvider} from "@chakra-ui/react";
import {Router, Outlet} from '@tanstack/react-location';
import {routes, location} from "@internal/Router";
import {Auth} from "@internal/Auth";
import {useEffect} from "react";


function App() {

  useEffect(() => {
    // 去年参加していて、初期化してない場合はローカルストレージを初期化する
    if (localStorage.getItem("isVisited") === "true" && localStorage.getItem("isInitialized") !== "true") {
      localStorage.clear()
      localStorage.setItem("isInitialized", "true")
      window.location.reload()
    } else {
      // 初回アクセス時に初期化フラグを立てる
      localStorage.setItem("isInitialized", "true")
    }
  }, [])

  return (
    <ChakraProvider>
      <Router routes={routes} location={location}>
        <Auth>
          <Outlet />
        </Auth>
      </Router>
    </ChakraProvider>
  )
}

export default App

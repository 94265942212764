import {ReactNode} from "react";
import {cleanPath, Navigate, useLocation, useNavigate} from '@tanstack/react-location';
import {Home} from "@internal/component/Home";
import {Close} from "@internal/component/Close";

interface Props {
  children: ReactNode
}

export const Auth = ({children}: Props) => {
  const location = useLocation()

  if (localStorage.getItem("receptionNumber") === null || localStorage.getItem("receptionNumber") === undefined || localStorage.getItem("receptionNumber") === "") {
    location.current.pathname = "./"
    return <Close />
  }

  return <>{children}</>
}

import {Box, Center, Container, Text} from "@chakra-ui/react";
import {Layout} from "@internal/component/Layout";
import backgroundUrl from "@internal/public/background.png";

export const Close = () => {
  return (
    <Box w='100%' h='100vh' backgroundImage={backgroundUrl} backgroundSize="cover">
      <Box w='100%' h='100vh' backgroundSize="contain" backgroundRepeat='no-repeat' backgroundPosition='center' backgroundColor="rgba(255,255,255,0.5)" backgroundBlendMode="lighten">
        <Center h='100vh'>
          <Text fontWeight='bold' fontSize='50px'>ご参加いただきありがとうございました！</Text>
        </Center>
      </Box>
    </Box>
  )
}

import { Route, ReactLocation } from '@tanstack/react-location';
import { Home } from '@internal/component/Home'
import { Bingo } from '@internal/component/Bingo'
import {Close} from "@internal/component/Close";

export const location = new ReactLocation();

export const routes: Route[] = [
  {
    path: '/',
    element: <Close />,
  },
  {
    path: 'bingo',
    children: [
      {
        path: '/',
        element: <Close />,
      },
    ],
  },
];
